// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/'
export const BOARDS = '/boards'
export const POS = '/pos'
export const RAFFLES = '/raffles'
export const PRODUCTS = '/products'
export const CATEGORY = '/category'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const ORDERS = '/orders'
export const CUSTOMERS = '/customers'
export const COUPONS = '/coupons'
export const SETTINGS = '/settings'
export const STAFF_MEMBERS = '/staff-members'
export const SITE_SETTINGS = '/site-settings'
export const RAFFLE_GAME = '/lanzar-sorteo'
export const EXPOSITORES = '/expositores'
export const CREDENCIALES = '/expositores/:id/credenciales'
export const EXPOSITORESFICHA = '/expositores/:id/ficha'
export const EXPOSITORESCREDENCIALES = '/credenciales'
export const PREREGISTRO = '/preregistro'
export const PREREGISTRODETALLE = '/preregistro/:id'
export const CHECKIN = '/checkin'
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$'
export const zoneOptions = [
    {
        label: 'PABELLÓN VENEZUELA',
        id: 'PABELLON_VENEZUELA',
    },
    {
        label: 'PABELLÓN COLOMBIA',
        id: 'PABELLON_COLOMBIA',
    },
    {
        label: 'ZONA DULCES',
        id: 'ZONA_DULCES',
    },
    {
        label: 'PATIO MEDALLO',
        id: 'PATIO_MEDALLO',
    },
    {
        label: 'PATIO LLANERO',
        id: 'PATIO_LLANERO',
    },
    {
        label: 'ZONA POLAR',
        id: 'ZONA_POLAR',
    },
    {
        label: 'FERIA COMIDA',
        id: 'FERIA_COMIDA',
    },
    {
        label: 'PATIO PRINCIPAL',
        id: 'PATIO_PRINCIPAL',
    },
    {
        label: 'PATIO FUENTE',
        id: 'PATIO_FUENTE',
    },
    {
        label: 'ZONA ENTRETENIMIENTO',
        id: 'ZONA_ENTRETENIMIENTO',
    },
    {
        label: 'ZONAS EXTERNAS',
        id: 'ZONAS_EXTERNAS',
    },
]
