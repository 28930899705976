import React from 'react'

type AuthProps = {
    isAuthenticated: boolean
    dataUser: {
        id: string
        role: string
        company: string
        email: string
        name: string
    }
    authenticate: Function
    signout: Function
}

export const AuthContext = React.createContext({} as AuthProps)

const isValidToken = () => {
    const token = localStorage.getItem('expotachira_token')
    // JWT decode & check token validity & expiration.
    if (token) return true
    return false
}

const getDataAuth = () => {
    const token = localStorage.getItem('expotachira_token')
    // JWT decode & check token validity & expiration.
    if (token) return JSON.parse(token)
    return null
}

const AuthProvider = (props: any) => {
    const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken())
    const [dataUser, setDataUser] = React.useState(getDataAuth())
    function authenticate(data, cb) {
        makeAuthenticated(true)
        setDataUser(null)
        localStorage.setItem('expotachira_token', JSON.stringify(data))
        setTimeout(cb, 100) // fake async
    }
    function signout(cb) {
        makeAuthenticated(false)
        setDataUser(null)
        localStorage.removeItem('expotachira_token')
        setTimeout(cb, 100)
        window.location.href = '/'
    }
    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                dataUser,
                authenticate,
                signout,
            }}
        >
            <>{props.children}</>
        </AuthContext.Provider>
    )
}

export default AuthProvider
